import {
  ApiAirportType,
  ApiFlightSearchGeoUnitsRequestFromJSON,
  ApiGeoUnit,
  ApiItemType,
  ApiSearchProductDataRequestFromJSON
} from '@ibe/api';
import { DestinationItem, DestinationItemCategory, PackageParams } from '@ibe/components';
import { ApiService } from '@ibe/services';

export const findDestination = (
  list: ApiGeoUnit[],
  code: string
): DestinationItem[] | undefined => {
  for (let item of list) {
    if (item.code === code) {
      return [
        ...Object.entries(item.children)
          .sort((a, b) => a[1].name?.localeCompare(b[1].name))
          .map(([, value]) => ({
            id: value.id,
            name: value.name,
            code: value.code,
            typeCode: value.typeCode,
            category: DestinationItemCategory.GEO_UNIT,
            parentId: item.id
          })),
        {
          id: item.id,
          name: item.name,
          code: item.code,
          typeCode: item.typeCode,
          category: DestinationItemCategory.GEO_UNIT
        }
      ]; // return chd separately
    }
    for (let childCode in item.children) {
      if (childCode === code) {
        const child = item.children[childCode];
        return [
          {
            id: child.id,
            name: child.name,
            code: child.code,
            typeCode: child.typeCode,
            category: DestinationItemCategory.GEO_UNIT
          }
        ];
      }
    }
  }
  return undefined;
};

export const enrichSearchParamsCodes = (
  params: Partial<PackageParams>,
  enrichmentData: UrlEnrichmentData
) => {
  const destinationsGeoUnits = enrichmentData.destinations;
  if (!!params.destinations && params.destinations.length > 0 && destinationsGeoUnits.length > 0) {
    const resultDestinations: DestinationItem[] = [];
    params.destinations.forEach(destinationItem => {
      const foundDestination = findDestination(destinationsGeoUnits, destinationItem.code);
      if (foundDestination) {
        resultDestinations.push(
          ...foundDestination.filter(fd => !resultDestinations.find(rd => fd.code === rd.code))
        );
      }
    });
    params.destinations = resultDestinations;
  }

  const { origins } = enrichmentData;
  if (!!params.origins && params.origins.length > 0 && origins.length > 0) {
    const resultOrigins: ApiGeoUnit[] = [];
    params.origins.forEach(origin => {
      const foundOrigin = origins.find(o => o.code === origin.code);
      if (foundOrigin) {
        resultOrigins.push(foundOrigin);
      }
    });
    params.origins = [...new Set(resultOrigins)];
  }
};

export const fetchDestinationsGeoUnits = async (api: ApiService): Promise<ApiGeoUnit[]> => {
  const destinationsRQ = ApiSearchProductDataRequestFromJSON({
    type: 'SearchProductDataRequest',
    search: '',
    offset: 0,
    limit: 100,
    itemType: ApiItemType.HOTEL
  });
  const destinationsRS = await api.searchProductData(destinationsRQ);
  return destinationsRS.geoUnits;
};

export const fetchOriginsGeoUnits = async (api: ApiService): Promise<ApiGeoUnit[]> => {
  const originsRQ = ApiFlightSearchGeoUnitsRequestFromJSON({
    type: 'FlightSearchGeoUnitsRequest',
    search: '',
    offset: 0,
    limit: 200,
    itemType: ApiItemType.TRANSPORT,
    airportType: ApiAirportType.ORIGIN
  });
  const originsRS = await api.searchGeoUnits(originsRQ);
  return originsRS.geoUnits;
};

export const originsChanged = (oldGeoUnits: ApiGeoUnit[], newGeoUnitCodes: string[]) => {
  const oldCodes = oldGeoUnits.map(gu => gu.code);
  return new Set(oldCodes) === new Set(newGeoUnitCodes);
};

export interface UrlEnrichmentData {
  destinations: ApiGeoUnit[];
  origins: ApiGeoUnit[];
}
